import { BucketDetails } from '../../domain/Bucket';
import { AtdnButton, AtdnText, Colors, ScreenSize, spacing } from '@ataden/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DeleteBucketModal } from './DeleteBucketModal';
import { getRouterBucketsPath, useGetProjectIdFromPath } from '../../../AuthenticatedRouter';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type DeleteBucketOptionProps = {
  bucket: BucketDetails;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
`;

const Title = styled(AtdnText)`
    margin-bottom: 8px;
`;

const Description = styled(AtdnText)`
    margin-bottom: 8px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: ${spacing(6)};

    @media (max-width: ${ScreenSize.mobile}px) {
        flex-direction: column;
    }
`;

const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DeleteBucketOption = ({ bucket }: DeleteBucketOptionProps) => {

  const navigate = useNavigate();
  const projectId = useGetProjectIdFromPath();

  const [modalVisibility, updateModalVisibility] = useState<boolean>(false);

  const onDelete = () => {
    navigate(getRouterBucketsPath(projectId));
  };

  return (
        <Container>
            <Header>
                <HeaderContent>
                    <Title type="important" color={Colors.Brown700}>
                        <FormattedMessage id="bucket.settings.delete.title"/>
                    </Title>
                    <Description>
                        <FormattedMessage id="bucket.settings.delete.description"/>
                    </Description>

                </HeaderContent>
                <AtdnButton size="normal" type="Danger" onClick={() => updateModalVisibility(true)}>
                    <FormattedMessage id="bucket.settings.delete.btn"/>
                </AtdnButton>
            </Header>
            {modalVisibility && <DeleteBucketModal bucket={bucket} onClosed={() => updateModalVisibility(false)}
                                                   onDeleted={onDelete}/>}
        </Container>
  );
};

import { AtdnText, Colors, spacing } from '@ataden/design-system';
import React from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { SyncStripePaymentMethod } from './SyncStripePaymentMethod';
import { useGetProjectIdFromPath } from '../../../AuthenticatedRouter';
import { CardList } from './CardList';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
    margin-bottom: ${spacing(7)};
`;

export const PaymentMethodComponent = () => {
  const [searchParams] = useSearchParams();
  const projectId = useGetProjectIdFromPath();

  const sessionId = searchParams.get('sessionId');

  if (sessionId) {
    return <SyncStripePaymentMethod projectId={projectId} sessionId={sessionId}/>;
  }

  return (
        <Container>
            <AtdnText scale={6} color={Colors.Brown700}>
                <FormattedMessage id='billing.payment-method.cards.title'/>
            </AtdnText>
            <CardList businessId={projectId}/>
        </Container>
  );
};

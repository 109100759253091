import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { AtadenLogo, AtdnText, Colors, ScreenSize } from '@ataden/design-system';

type BigPictureLayoutProps = {
  title?: string;
  description?: string;

  children: ReactNode;
};

const Page = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${Colors.Gray100};
    background-image: url("/blocks.jpg");
    background-size: cover;
    background-position: center;

    padding: 0 40px;

    @media (max-width: ${ScreenSize.mobile}px) {
        padding: 0 16px;
    }

`;

const LogoWrapper = styled.div`
    position: fixed;
    top: 16px;

    @media (max-width: ${ScreenSize.mobile}px) {
        display: flex;
        position: static;
    }
`;

const PageContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    padding-top: 120px;

    @media (max-width: ${ScreenSize.tablet}px) {
        display: flex;
        position: static;
        justify-content: start;
        margin-top: 32px;
    }

    @media (max-width: ${ScreenSize.mobile}px) {
        padding-top: 60px;
    }
`;

const PageDescriptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 16px;

    padding-bottom: 60px;

    @media (max-width: ${ScreenSize.tablet}px) {
        flex-direction: column;
        gap: 24px;

        padding-bottom: 32px;
    }
`;

const PageTitleSection = styled.div`
    display: flex;

    width: 100%;
`;

const PageDescriptionSection = styled.div`
    display: flex;

    width: 100%;
    max-width: 480px;
`;

const Title = styled(AtdnText)`
    @media (max-width: ${ScreenSize.mobile}px) {
        font-size: 30px;
        line-height: 28px;
    }

    @media (max-width: ${ScreenSize.tablet}px) {
        font-size: 42px;
        line-height: 50px;
    }
`;

const Description = styled(AtdnText)`
    @media (max-width: ${ScreenSize.mobile}px) {
        font-size: 18px;
        line-height: 20px;
    }
`;

export const BigPictureLayout = ({ title, description, children }: BigPictureLayoutProps) => {

  const displayHeader = title || description;

  return (
        <Page>
            <LogoWrapper>
                <AtadenLogo width="148px" height="56px" whiteMode/>
            </LogoWrapper>

            <PageContent>
                {displayHeader && (<PageDescriptionContainer>
                    {title && (<PageTitleSection>
                        <Title scale={9} color={Colors.White}>
                            {title}
                        </Title>
                    </PageTitleSection>)}
                    {description && (<PageDescriptionSection>
                        <Description scale={5} color={Colors.White}>
                            {description}
                        </Description>
                    </PageDescriptionSection>)}
                </PageDescriptionContainer>)}
                {children}
            </PageContent>
        </Page>
  );
};

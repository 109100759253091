import { AtdnTab, AtdnTabs, AtdnText, Colors } from '@ataden/design-system';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { matchRoutes, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  getRouterSettingsApiKeysPath,
  getRouterSettingsBillingPath,
  getRouterSettingsPaymentMethodPath,
  useGetProjectIdFromPath,
} from '../../AuthenticatedRouter';
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import type { Location } from 'history';
import { PageHeader } from '../../layout/PageLayout';


enum SettingsPageTabs {
  settingApiKey = 'setting_apiKey',
  settingPaymentMethod = 'setting_paymentMethod',
  settingBilling = 'setting_billing',
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 52px 20px 20px 20px;
`;


const TabContainer = styled.div`
    display: flex;
    width: 100%;
    overflow-y: auto;
`;

const matchPathWithTabs = (projectId: string, currentLocation: Location): string => {
  const allSupportedRoutes = [
    { path: getRouterSettingsApiKeysPath(projectId), tabId: SettingsPageTabs.settingApiKey },
    { path: getRouterSettingsPaymentMethodPath(projectId), tabId: SettingsPageTabs.settingPaymentMethod },
    { path: getRouterSettingsBillingPath(projectId), tabId: SettingsPageTabs.settingBilling },
  ];
  const matches = matchRoutes(allSupportedRoutes, currentLocation);
  if (matches === null || matches.length === 0) {
    return SettingsPageTabs.settingApiKey;
  }

  return (matches[0].route as any).tabId;
};


export const SettingsLayout = () => {
  const projectId = useGetProjectIdFromPath();
  const currentLocation = useLocation();

  const [currentTab, updateCurrentTab] = useState<string>(matchPathWithTabs(projectId, currentLocation));
  const intl = useIntl();
  const navigate = useNavigate();

  const onTabSelected = (tabId: string) => {
    updateCurrentTab(tabId);

    if (tabId === SettingsPageTabs.settingApiKey) {
      navigate(getRouterSettingsApiKeysPath(projectId));
      return;
    }

    if (tabId === SettingsPageTabs.settingPaymentMethod) {
      navigate(getRouterSettingsPaymentMethodPath(projectId));
      return;
    }

    if (tabId === SettingsPageTabs.settingBilling) {
      navigate(getRouterSettingsBillingPath(projectId));
      return;
    }
  };

  return (
        <Container>
            <PageHeader>
                <AtdnText scale={7} color={Colors.Brown700}>
                    <FormattedMessage id="settings.title"/>
                </AtdnText>
            </PageHeader>

            <AtdnTabs currentTab={currentTab} onTabSelected={onTabSelected}>
                <AtdnTab id={SettingsPageTabs.settingApiKey} title={intl.formatMessage({ id: 'settings.tabs.api-key' })}
                         disabled={false}>
                    <TabContainer>
                        <Outlet/>
                    </TabContainer>
                </AtdnTab>

                <AtdnTab id={SettingsPageTabs.settingPaymentMethod}
                         title={intl.formatMessage({ id: 'settings.tabs.payment-methods' })}
                         disabled={false}>
                    <TabContainer>
                        <Outlet/>
                    </TabContainer>
                </AtdnTab>

                <AtdnTab id={SettingsPageTabs.settingBilling}
                         title={intl.formatMessage({ id: 'settings.tabs.billing' })}
                         disabled={false}>
                    <TabContainer>
                        <Outlet/>
                    </TabContainer>
                </AtdnTab>
            </AtdnTabs>
        </Container>
  );
};

import React from 'react';
import styled from 'styled-components';
import { BucketInfo } from './BucketInfo';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';
import { Bucket } from '../domain/Bucket';

type BucketListProps = {
  projectId: string;
  buckets: Bucket[];
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: /*24px*/ minmax(0px, 1fr) 100px 100px;
    border-bottom: 1px solid ${Colors.Gray100};
    padding: ${spacing(6)};
    margin-bottom: ${spacing(4)};
`;


export const BucketList = ({ projectId, buckets }: BucketListProps) => {

  return (
        <Container>
            <HeaderContainer>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="buckets.list.header.name"/>
                </AtdnText>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="buckets.list.header.size"/>
                </AtdnText>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="buckets.list.header.createdDate"/>
                </AtdnText>
            </HeaderContainer>
            {buckets.map((bucket) => (
                <BucketInfo key={bucket.id} projectId={projectId} bucket={bucket}/>
            ))}
        </Container>
  );
};

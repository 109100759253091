import React, { useEffect, useState } from 'react';
import { useGetCards } from '../../repositories/PaymentRepository.hook';
import { Card } from '../../domain/Card';
import { NoCardPaymentYet } from './EmptyCardPaymentYet';
import { CardPaymentMethodCreation } from './CardPaymentMethodCreation';
import styled from 'styled-components';
import { CardComponent } from './CardComponent';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';

type CardListProps = {
  businessId: string;
};

const AddCreditCardContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
    padding: 16px 24px 16px 8px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const CardTableHeader = styled.div`
    display: grid;
    grid-template-columns: 100px minmax(0px, 1fr) 175px 175px 100px;
    column-gap: 16px;
    width: 100%;

    padding: ${spacing(6)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

export const CardList = ({ businessId }: CardListProps) => {

  const getCards = useGetCards(businessId);

  const [cards, updateCards] = useState<Card[]>([]);

  useEffect(() => {
    getCards()
      .then(updateCards);
  }, []);

  const onCardSelected = (card: Card) => {
    updateCards(cards.map((c) => {
      return {
        ...c,
        current: c.id === card.id,
      };
    }));
  };

  const onCardDeleted = (card: Card) => {
    updateCards(cards.filter((c) => c.id !== card.id));

  };

  if (cards.length === 0) {
    return <NoCardPaymentYet/>;
  }

  return (
        <Container>
            <AddCreditCardContainer>
                <CardPaymentMethodCreation/>
            </AddCreditCardContainer>

            <CardTableHeader>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="billing.payment-method.cards.header.default"/>
                </AtdnText>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="billing.payment-method.cards.header.last4"/>
                </AtdnText>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="billing.payment-method.cards.header.createdDate"/>
                </AtdnText>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="billing.payment-method.cards.header.expirationDate"/>
                </AtdnText>
                <AtdnText type="important" color={Colors.Brown700}>
                    <FormattedMessage id="billing.payment-method.cards.header.options"/>
                </AtdnText>
            </CardTableHeader>
            {cards.map((card) => (
                <CardComponent key={card.id} card={card} businessId={businessId}
                               onCardSelected={() => onCardSelected(card)} onCardDeleted={() => onCardDeleted(card)}/>
            ))}
        </Container>
  );
};

import { BucketDetails } from '../../domain/Bucket';
import styled from 'styled-components';
import { AtdnCopyButton, AtdnText, Colors } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getRouterSettingsApiKeysPath, useGetProjectIdFromPath } from '../../../AuthenticatedRouter';
import { useGetCurrentBusinessInfo } from '../../../business/BusinessStore';

type S3SectionProps = {
  bucket: BucketDetails;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled(AtdnText)`
    margin-bottom: 36px;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
`;

const CopyKeyButton = styled(AtdnCopyButton)`
    margin-left: 8px;
`;

const UrlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 48px;
`;

const UrlSection = styled.div`

`;

const UrlTitle = styled(AtdnText)`
    margin-bottom: 8px;
`;

const UrlValueRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const UrlValue = styled(AtdnText)`

`;
 
export const ApiSectionS3 = ({ bucket }: S3SectionProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const projectId = useGetProjectIdFromPath();

  const getCurrentBusinessInfo = useGetCurrentBusinessInfo();

  const pathUrl = `https://${process.env.REACT_APP__S3_DOMAIN}/${bucket.id}`;
  const virtualHostBucketUrl = `https://${bucket.dns}.${getCurrentBusinessInfo()?.dns}.${process.env.REACT_APP__S3_DOMAIN}`;

  const onApiKeyPage = () => {
    navigate(getRouterSettingsApiKeysPath(projectId));
  };

  return (
        <Container>
            <Title scale={6} color={Colors.Brown700}>
                <FormattedMessage id="bucket.api.s3.title"/>
            </Title>
            <Description>
                <AtdnText>
                    <FormattedMessage id="bucket.api.s3.description"/>
                </AtdnText>
                <AtdnText>
                    <a
                        href={getRouterSettingsApiKeysPath(projectId)} onClick={onApiKeyPage}><FormattedMessage
                        id="bucket.api.s3.api-key"/>
                    </a>
                </AtdnText>
            </Description>

            <UrlsContainer>
                <UrlSection>
                    <UrlTitle type="important" color={Colors.Brown700}>
                        <FormattedMessage id="bucket.api.s3.section.virtual-host"/>
                    </UrlTitle>
                    <UrlValueRow>
                        <UrlValue color={Colors.Purple700}>{virtualHostBucketUrl}</UrlValue>
                        <CopyKeyButton textToCopy={virtualHostBucketUrl}
                                       tooltipToCopy={intl.formatMessage({ id: 'bucket.api.s3.url.copy' })}
                                       tooltipCopied={intl.formatMessage({ id: 'bucket.api.s3.url.copied' })}/>
                    </UrlValueRow>
                </UrlSection>
                <UrlSection>
                    <UrlTitle type="important" color={Colors.Brown700}>
                        <FormattedMessage id="bucket.api.s3.section.path"/>
                    </UrlTitle>
                    <UrlValueRow>
                        <UrlValue color={Colors.Purple700}>{pathUrl}</UrlValue>
                        <CopyKeyButton textToCopy={pathUrl}
                                       tooltipToCopy={intl.formatMessage({ id: 'bucket.api.s3.url.copy' })}
                                       tooltipCopied={intl.formatMessage({ id: 'bucket.api.s3.url.copied' })}/>
                    </UrlValueRow>
                </UrlSection>
            </UrlsContainer>
        </Container>
  );
};
